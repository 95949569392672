@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css?family=Roboto");
body {
  font-family: "Roboto";
}
.ant-layout.lay-out {
  background-color: white;
}
div#root {
  height: 100%;
}
.login-form {
  width: 300px;
}
.login-form-forgot {
  float: right;
  color: lightblue;
}
.ant-btn.login-form-button {
  width: 100%;
}
.head {
  background-color: white;
  border: 2px solid lightgray;
  padding: 10px;
}
.footer {
  margin-top: auto;
  text-align: center;
  background-color: lightgray;
  color: #848282;
  padding: 15px;
}
.contents {
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.inputStyles .ant-input {
  background-color: rgb(235, 245, 253);
  color: rgb(148, 144, 144);
  border: none;
  border-radius: 0px;
  font-size: 14px;
  padding: 20px;
  font-weight: 600;
}
.ant-col-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
  margin-top: 10px;
}
.iconSize.anticon svg {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  color: lightblue;
}
.percentage {
  color: white;
}
.container1 {
  background-color: rgb(115, 191, 178);
}
.container2 {
  background-color: rgb(180, 218, 193);
  color: white;
}
.container3 {
  background-color: rgb(248, 252, 202);
  color: black;
}
.container4 {
  background-color: rgb(233, 87, 126);
}
.tech-image {
  margin-top: 10px;
  margin-right: 10x;
  width: 60px;
  height: 60px;
}
.pie-chart.ant-card {
  width: 500px;
  height: 550px;
  margin-top: 70px;
  margin-left: 20px;
  border: 2px solid gray;
}
.bar-chart.ant-card {
  width: 700px;
  height: 500px;
  margin-top: 20px;
  border: 2px solid gray;
  margin-bottom: 10px;
}
.offer-image {
  margin-top: 10px;
  width: 60px;
  height: 60px;
}
span.ant-calendar-picker-input.ant-input {
  width: 200px;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #afb5b4a6;
}
.labels {
  font-size: 20px;
  color: #000000b8;
  float: right;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}
.ant-avatar.ant-avatar-circle {
  background-color: #06d9fd;
  margin: 5px;
}
a {
  text-decoration: none !important;
}
.head-container {
  display: flex;
}
.analytic-flex {
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  margin-right: 2%;
}
.ant-card.pie-chart.ant-card-bordered {
  margin-left: 0px;
}
.icons.anticon svg {
  display: block;
  width: 40px;
  height: 40px;
  color: white;
  margin-top: 20px;
}
.interview-detail {
  text-align: center;
}
.interview-detail h3 {
  font-size: 14px;
}
