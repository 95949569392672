@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  font-family: "Roboto";
}
.ant-layout.lay-out {
  background-color: white;
}
div#root {
  height: 100%;
}
.login-form {
  width: 300px;
}
.login-form-forgot {
  float: right;
  color: lightblue;
}
.ant-btn.login-form-button {
  width: 100%;
}
.head {
  background-color: white;
  border: 2px solid lightgray;
  padding: 10px;
}
.footer {
  margin-top: auto;
  text-align: center;
  background-color: lightgray;
  color: #848282;
  padding: 15px;
}
.contents {
  -webkit-align-self: center;
          align-self: center;
  margin-top: auto;
  margin-bottom: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.inputStyles .ant-input {
  background-color: rgb(235, 245, 253);
  color: rgb(148, 144, 144);
  border: none;
  border-radius: 0px;
  font-size: 14px;
  padding: 20px;
  font-weight: 600;
}
.ant-col-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
  margin-top: 10px;
}
.iconSize.anticon svg {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  color: lightblue;
}
.percentage {
  color: white;
}
.container1 {
  background-color: rgb(115, 191, 178);
}
.container2 {
  background-color: rgb(180, 218, 193);
  color: white;
}
.container3 {
  background-color: rgb(248, 252, 202);
  color: black;
}
.container4 {
  background-color: rgb(233, 87, 126);
}
.tech-image {
  margin-top: 10px;
  margin-right: 10x;
  width: 60px;
  height: 60px;
}
.pie-chart.ant-card {
  width: 500px;
  height: 550px;
  margin-top: 70px;
  margin-left: 20px;
  border: 2px solid gray;
}
.bar-chart.ant-card {
  width: 700px;
  height: 500px;
  margin-top: 20px;
  border: 2px solid gray;
  margin-bottom: 10px;
}
.offer-image {
  margin-top: 10px;
  width: 60px;
  height: 60px;
}
span.ant-calendar-picker-input.ant-input {
  width: 200px;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #afb5b4a6;
}
.labels {
  font-size: 20px;
  color: #000000b8;
  float: right;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}
.ant-avatar.ant-avatar-circle {
  background-color: #06d9fd;
  margin: 5px;
}
a {
  text-decoration: none !important;
}
.head-container {
  display: -webkit-flex;
  display: flex;
}
.analytic-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 2%;
  margin-right: 2%;
}
.ant-card.pie-chart.ant-card-bordered {
  margin-left: 0px;
}
.icons.anticon svg {
  display: block;
  width: 40px;
  height: 40px;
  color: white;
  margin-top: 20px;
}
.interview-detail {
  text-align: center;
}
.interview-detail h3 {
  font-size: 14px;
}

.headColor {
  font-size: 20px;
  color: #06d9fd;
  font-family: "Roboto", sans-serif;
  padding: 5px;
}
.ant-form-item-label label {
  font-size: 15px !important;
  color: #9c9898a6 !important;
  font-family: "Roboto", sans-serif !important;
}
.ant-input.inputstyle {
  background-color: #c9f0f8;
  width: 300px;
  border-radius: 0px;
  border: none;
}
.ant-input.inputstyle[disabled] {
  background-color: #f5f5f5;
}
.changeColor {
  color: #9c9898a6;
  margin-top: 50px;
}
.ant-btn.changeColor.ant-btn-lg {
  color: white;
  background-color: #368ce0;
  border-color: #c9f0f8;
  padding: 5px;
}
.ant-select-selection.ant-select-selection--single {
  border: none;
  width: 300px;
  background-color: #c9f0f8;
  border-radius: 0px;
}
.text-font {
  font-family: "Roboto", sans-serif;
}
.search-box {
  width: 200px;
  margin-left: auto;
  padding-right: 5px;
}
.table-column-id {
  width: 10px;
}
.table-column {
  width: 250px;
}
.flex-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  width: 80%;
}
tr:nth-child(even) {
  background: #f5f5f0;
}
label {
  cursor: pointer;
  color: #66e0ff;
}
th,
td {
  text-align: center !important;
}
.ant-input.changeTextArea {
  border: none;
  background-color: #c9f0f8;
  width: 300px;
}
.ant-calendar-picker-input.ant-input {
  background-color: #c9f0f8;
  border: none;
}
.ant-calendar-picker {
  border: none;
  width: 300px;
  background-color: #c9f0f8;
  border-radius: 0px;
  margin-right: 50px;
}
.ant-tabs-tab {
  color: #612784;
  font-size: 20px;
}
.upload-file {
  width: 300px;
  height: 100px;
}
.input-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.content-style {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-right: auto;
  margin-left: auto;
  width: 65%;
  height: 80%;
}
.input-flex > .primary-flex {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
  -webkit-flex: 1 1;
          flex: 1 1;
}
.input-flex > .secondary-flex {
  -webkit-flex: 1 1;
          flex: 1 1;
}
.ant-upload.ant-upload-drag {
  width: 85% !important;
}
.button-style {
  text-align: right;
  margin-right: 8rem;
}
.container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 100%;
}
.flex-table {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: auto;
}
.pagination {
  padding: 5px;
  -webkit-align-self: center;
          align-self: center;
}
.flex-menu {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0px;
}
.ant-tabs-bar.ant-tabs-top-bar {
  margin: 10px;
  margin-top: 0px;
}
.button-close {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 2%;
  margin-right: 20%;
}
.flex-tab {
  display: -webkit-flex;
  display: flex;
}
.ant-tabs.ant-tabs-top.ant-tabs-line {
  width: 100%;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0px !important;
}
.padding-style {
  margin-top: 0.3rem;
}
.loding {
  text-align: center;
}
.loding h2 {
  padding-top: 5em;
  font-size: 20px;
}
.ant-menu-item {
  padding-left: 10px !important;
  color: black !important;
  margin-bottom: 0px !important;
  height: 30px !important;
  line-height: 30px !important;
}
.ant-menu-submenu-title {
  background-color: skyblue !important;
  margin-bottom: 0px !important;
}
.ant-menu {
  background-color: white !important;
}
.button {
  margin-left: 60px;
  padding-top: 5px;
}
.filter-icon {
  background-color: white;
  margin-right: 0.3rem;
}
.remove-button {
  padding-bottom: 5px;
}
.delete-icon.anticon svg {
  width: 40px;
  height: 20px;
}

