.headColor {
  font-size: 20px;
  color: #06d9fd;
  font-family: "Roboto", sans-serif;
  padding: 5px;
}
.ant-form-item-label label {
  font-size: 15px !important;
  color: #9c9898a6 !important;
  font-family: "Roboto", sans-serif !important;
}
.ant-input.inputstyle {
  background-color: #c9f0f8;
  width: 300px;
  border-radius: 0px;
  border: none;
}
.ant-input.inputstyle[disabled] {
  background-color: #f5f5f5;
}
.changeColor {
  color: #9c9898a6;
  margin-top: 50px;
}
.ant-btn.changeColor.ant-btn-lg {
  color: white;
  background-color: #368ce0;
  border-color: #c9f0f8;
  padding: 5px;
}
.ant-select-selection.ant-select-selection--single {
  border: none;
  width: 300px;
  background-color: #c9f0f8;
  border-radius: 0px;
}
.text-font {
  font-family: "Roboto", sans-serif;
}
.search-box {
  width: 200px;
  margin-left: auto;
  padding-right: 5px;
}
.table-column-id {
  width: 10px;
}
.table-column {
  width: 250px;
}
.flex-container {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  width: 80%;
}
tr:nth-child(even) {
  background: #f5f5f0;
}
label {
  cursor: pointer;
  color: #66e0ff;
}
th,
td {
  text-align: center !important;
}
.ant-input.changeTextArea {
  border: none;
  background-color: #c9f0f8;
  width: 300px;
}
.ant-calendar-picker-input.ant-input {
  background-color: #c9f0f8;
  border: none;
}
.ant-calendar-picker {
  border: none;
  width: 300px;
  background-color: #c9f0f8;
  border-radius: 0px;
  margin-right: 50px;
}
.ant-tabs-tab {
  color: #612784;
  font-size: 20px;
}
.upload-file {
  width: 300px;
  height: 100px;
}
.input-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.content-style {
  align-items: flex-start;
  margin-right: auto;
  margin-left: auto;
  width: 65%;
  height: 80%;
}
.input-flex > .primary-flex {
  align-items: flex-start;
  justify-content: flex-start !important;
  flex: 1;
}
.input-flex > .secondary-flex {
  flex: 1;
}
.ant-upload.ant-upload-drag {
  width: 85% !important;
}
.button-style {
  text-align: right;
  margin-right: 8rem;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.flex-table {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  overflow: auto;
}
.pagination {
  padding: 5px;
  align-self: center;
}
.flex-menu {
  display: flex;
  margin-bottom: 0px;
}
.ant-tabs-bar.ant-tabs-top-bar {
  margin: 10px;
  margin-top: 0px;
}
.button-close {
  display: flex;
  justify-content: flex-end;
  margin-top: 2%;
  margin-right: 20%;
}
.flex-tab {
  display: flex;
}
.ant-tabs.ant-tabs-top.ant-tabs-line {
  width: 100%;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0px !important;
}
.padding-style {
  margin-top: 0.3rem;
}
.loding {
  text-align: center;
}
.loding h2 {
  padding-top: 5em;
  font-size: 20px;
}
.ant-menu-item {
  padding-left: 10px !important;
  color: black !important;
  margin-bottom: 0px !important;
  height: 30px !important;
  line-height: 30px !important;
}
.ant-menu-submenu-title {
  background-color: skyblue !important;
  margin-bottom: 0px !important;
}
.ant-menu {
  background-color: white !important;
}
.button {
  margin-left: 60px;
  padding-top: 5px;
}
.filter-icon {
  background-color: white;
  margin-right: 0.3rem;
}
.remove-button {
  padding-bottom: 5px;
}
.delete-icon.anticon svg {
  width: 40px;
  height: 20px;
}
